import * as React from 'react';
import { useIntl } from 'react-intl';
import styles from './Disconnected.module.scss';

export default function Disconnected() {
    const intl = useIntl();
    return (
        <div className={styles.root}>
            <h3>
                {intl.formatMessage({
                    id: 'disconnected.title',
                })}
            </h3>
            <span>
                {intl.formatMessage({
                    id: 'disconnected.description',
                })}
            </span>
        </div>
    );
}
