import * as React from 'react';
import Dragable from 'react-draggable';
import type { LocalVideoTrack } from '@zoom/videosdk';
import type { ExtendedLocalVideoTrack } from 'core/hooks/video/context';
import styles from '../VideoStreams.module.scss';

interface VideoParticipantProps {
    videoTrack: ExtendedLocalVideoTrack | null;
    classes?: typeof styles;
    draggable?: boolean;
    videoRef: React.MutableRefObject<HTMLVideoElement | null>;
}

export default function VideoParticipant({
    classes,
    videoTrack,
    draggable = false,
    videoRef,
}: VideoParticipantProps) {
    React.useEffect(() => {
        let started: null | LocalVideoTrack = null;

        if (videoTrack && videoRef.current) {
            const theTrack = videoTrack;
            videoTrack
                .start(videoRef.current)
                .then(() => {
                    started = theTrack;
                })
                .catch((error) => {
                    console.warn('error starting video track', error);
                });
        }

        return () => {
            if (
                started &&
                (started as LocalVideoTrack & { isVideoStarted: boolean })
                    .isVideoStarted
            ) {
                started.stop().catch((error) => {
                    console.warn('error stopping video track', error);
                });
            }
        };
    }, [videoRef, videoTrack]);

    return (
        <>
            <Dragable disabled={!draggable} bounds="parent">
                <div className={classes?.root}>
                    {videoTrack && (
                        <video
                            ref={videoRef}
                            muted
                            autoPlay
                            playsInline
                            className={classes?.video}
                        />
                    )}
                </div>
            </Dragable>
        </>
    );
}
