import { ClientFirestoreSessionIds } from '@bondvet/types/telehealth';

type ReadSessionDataFromLocalStorageResult = null | {
    client: ClientFirestoreSessionIds;
    sessionPassword: string;
};

const PARAMETER_IDENTIFIER = 'session';

export function readSessiondataFromLocalStorage(): ReadSessionDataFromLocalStorageResult {
    const sessionFromStorage =
        window.localStorage.getItem(PARAMETER_IDENTIFIER);
    if (!sessionFromStorage) {
        return null;
    }

    return JSON.parse(sessionFromStorage);
}

export function setSessiondataInLocalStorage(
    sessionData: ClientFirestoreSessionIds,
    sessionPassword: string,
): void {
    window.localStorage.setItem(
        PARAMETER_IDENTIFIER,
        JSON.stringify({ client: sessionData, sessionPassword }),
    );
}

export function clearLocalStorage(): void {
    window.localStorage.clear();
}
