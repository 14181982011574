import * as React from 'react';
import type { LocalVideoTrack } from '@zoom/videosdk';

export type CallMetaData = {
    hasMicrophonePermission: boolean;
    hasCameraPermission: boolean;
};

export type ExtendedLocalVideoTrack = LocalVideoTrack & {
    deviceId: string;
    isVideoStarted: boolean;
};

export type VideoContextData = {
    microphoneOn: boolean;
    cameraOn: boolean;
    otherParticipantJoined: boolean;
    canSwitchCameras: boolean;
    localVideoTrack: ExtendedLocalVideoTrack | null;
    localVideoRef: React.MutableRefObject<HTMLVideoElement | null>;
    hasReceivedPermissions: boolean;
    hasLostPermissions: boolean;
    userPermissionsGranted: boolean;
};

export const InitialVideoContext: VideoContextData = {
    microphoneOn: true,
    cameraOn: true,
    canSwitchCameras: false,
    otherParticipantJoined: false,
    localVideoTrack: null,
    localVideoRef: { current: null },
    hasReceivedPermissions: false,
    hasLostPermissions: false,
    userPermissionsGranted: false,
};

export type VideoContextUpdaters = {
    toggleMicrophone: () => void;
    toggleCamera: () => void;
    switchCamera: () => void;
    joinSession: (
        zoomAuthToken: string,
        sessionName: string,
        sessionPassword: string,
        onDisconnected: undefined | (() => void),
    ) => void;
    endVideoCall: (andEndSession?: boolean) => void;
    setSessionMetaData: ({
        hasMicrophonePermission,
        hasCameraPermission,
    }: CallMetaData) => void;
};

export type VideoContextType = (VideoContextData & VideoContextUpdaters) & {
    hasLocalVideo: boolean;
    hasRemoteVideo: boolean;
};
const VideoContext = React.createContext<VideoContextType>({
    toggleMicrophone: () => {},
    toggleCamera: () => {},
    switchCamera: () => {},
    joinSession: () => {},
    endVideoCall: () => {},
    setSessionMetaData: () => {},
    hasLocalVideo: false,
    hasRemoteVideo: false,
    ...InitialVideoContext,
});
export default VideoContext;
