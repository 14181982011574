import * as React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
    Auth,
    connectAuthEmulator,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithCustomToken,
    signInWithPopup,
    signOut,
    User,
} from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import useStorageEmulator from '../useStorageEmulator';
import { FirebaseContextData, FirebaseContextType } from './context';

export default function useFirebaseContextHandler(): FirebaseContextType {
    const { initStorageEmulator } = useStorageEmulator();
    const [{ firebase, user, isAdmin, authLoading }, setValue] =
        React.useState<FirebaseContextData>({
            firebase: null,
            user: null,
            isAdmin: false,
            authLoading: true,
        });

    React.useEffect(() => {
        const app = initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
                process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        });
        setValue((prev) => ({
            ...prev,
            firebase: app,
        }));
    }, []);

    // Log errors
    React.useEffect(() => {
        if (firebase) {
            const analytics = getAnalytics(firebase);

            const errorHandler = (event: ErrorEvent) => {
                logEvent(analytics, 'exception', {
                    description: event.error,
                });
            };

            window.addEventListener('error', errorHandler);

            return () => {
                window.removeEventListener('error', errorHandler);
            };
        }

        return () => {};
    }, [firebase]);

    React.useEffect(() => {
        function connectToEmulatorInDevelopment(auth: Auth, app: FirebaseApp) {
            if (process.env.REACT_APP_ENV === 'develop') {
                if (process.env.REACT_APP_AUTH_EMULATOR) {
                    connectAuthEmulator(
                        auth,
                        process.env.REACT_APP_AUTH_EMULATOR,
                        {
                            disableWarnings: true,
                        },
                    );
                }

                if (process.env.REACT_APP_FIRESTORE_EMULATOR) {
                    const firestore = getFirestore(app);
                    const { hostname, port, protocol } = new URL(
                        process.env.REACT_APP_FIRESTORE_EMULATOR,
                    );
                    connectFirestoreEmulator(
                        firestore,
                        hostname,
                        protocol === 'https:' ? 443 : parseInt(port, 10),
                    );
                }
                initStorageEmulator(app);
            }
        }

        if (firebase) {
            const auth = getAuth(firebase);
            connectToEmulatorInDevelopment(auth, firebase);
            const unsubscribe = onAuthStateChanged(
                auth,
                (newUser: User | null) => {
                    if (newUser) {
                        setValue((prev) => ({
                            ...prev,
                            authLoading: false,
                            user: newUser,
                        }));
                    } else {
                        setValue((prev) => ({
                            ...prev,
                            authLoading: false,
                            user: null,
                        }));
                    }
                },
            );

            return () => unsubscribe();
        }

        return () => {};
    }, [firebase, initStorageEmulator]);

    React.useEffect(() => {
        if (user) {
            user.getIdTokenResult().then(({ claims }) => {
                setValue((prev) => ({
                    ...prev,
                    isAdmin: Boolean(claims.telehealthAdmin) || false,
                }));
            });
        }
    }, [user]);

    const signIntoFirestore = React.useCallback(
        async (authToken: string) => {
            if (!firebase) {
                return;
            }
            try {
                const auth = getAuth(firebase);
                await signInWithCustomToken(auth, authToken);
            } catch (error) {
                console.warn(error);
            }
        },
        [firebase],
    );

    const signOutOfFirebase = React.useCallback(async () => {
        if (firebase) {
            const auth = getAuth(firebase);
            await signOut(auth);
        }
    }, [firebase]);

    const signInWithGooglePopup = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        await signInWithPopup(auth, provider);
    };

    return {
        firebase,
        signIntoFirestore,
        signInWithGooglePopup,
        signOutOfFirebase,
        user,
        isAdmin,
        authLoading,
    };
}
