import * as React from 'react';
import type { FirebaseApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

export default function useStorageEmulator(): {
    initStorageEmulator: (firebase: FirebaseApp) => void;
    emulatorReady: boolean;
} {
    const [emulatorReady, setEmulatorReady] = React.useState(
        () => !!process.env.REACT_APP_STORAGE_EMULATOR,
    );
    const initStorageEmulator = React.useCallback((firebase: FirebaseApp) => {
        if (process.env.REACT_APP_STORAGE_EMULATOR) {
            const { hostname, port } = new URL(
                process.env.REACT_APP_STORAGE_EMULATOR,
            );
            const connectToEmulatorInDevelopment = () => {
                const auth = getAuth(firebase);
                const storage = getStorage(firebase);

                if (auth.currentUser) {
                    auth.currentUser.getIdToken().then((idToken) => {
                        connectStorageEmulator(
                            storage,
                            hostname,
                            parseInt(port, 10),
                            {
                                mockUserToken: idToken,
                            },
                        );
                    });

                    setEmulatorReady(true);
                }
            };

            connectToEmulatorInDevelopment();
        }
    }, []);

    return {
        initStorageEmulator,
        emulatorReady,
    };
}
