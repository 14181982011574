import useVideoContext from 'core/hooks/video/useVideoContext';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styles from './PermissionWarning.module.scss';

export default function PermissionWarning() {
    const { userPermissionsGranted } = useVideoContext();
    const intl = useIntl();

    return !userPermissionsGranted ? (
        <div className={styles.root}>
            {intl.formatMessage({ id: 'callPage.permissionError' })}
        </div>
    ) : (
        <></>
    );
}
