/* eslint-disable react/jsx-no-literals */
import { gql, useApolloClient } from '@apollo/client';
import * as React from 'react';
import TextField from 'core/components/TextField';
import useFirebaseContext from 'core/hooks/firebase/useFirebaseContext';
import { GraphQLClientNames } from 'lib/types';
import useCallContext from '../../hooks/useCallContext/useCallContext';
import useVetspireLocationId from '../../hooks/useVetspireLocationId';
import NextButton from '../NextButton';
import styles from '../PaymentPage/PaymentPage.module.scss';
// eslint-disable-next-line max-len
import useClientKnownCreditCardForm from '../PaymentPage/useCreditCardForm/useClientKnownCreditCardForm';
import StripeInput from '../StripeInput/StripeInput';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import { Step } from '../../lib/types';

export const enterVideoCallMutation = gql`
    mutation EnterVideoCall($sessionId: String!) {
        enterVideoCall(sessionId: $sessionId)
    }
`;

export type EnterVideoCallResult = { enterVideoCall: string };
function DebugSkipForm() {
    const {
        cardCvcRef,
        cardDataValid,
        handleSubmit,
        processing: creditCardProcessing,
    } = useClientKnownCreditCardForm();
    const { signIntoFirestore } = useFirebaseContext();
    const { setStep } = useClientContext();
    const [sessionId, setSessionId] = React.useState<string>('');
    const [enteringAsProvider, setEnteringAsProvider] =
        React.useState<boolean>(false);
    const apolloClient = useApolloClient();
    const { setSessionIds } = useCallContext();

    const { loading: vetspireIdLoading, data: vetspireLocationIdData } =
        useVetspireLocationId();

    React.useEffect(() => {
        if (cardDataValid && !creditCardProcessing && vetspireLocationIdData) {
            localStorage.clear();
            sessionStorage.clear();
            handleSubmit(
                signIntoFirestore,
                vetspireLocationIdData.telehealthVetspireLocationId,
                () => {},
                true,
            ).then();
        }
    }, [
        cardDataValid,
        handleSubmit,
        signIntoFirestore,
        creditCardProcessing,
        vetspireLocationIdData,
    ]);

    const handleChangeSessionId = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setSessionId(value);
        },
        [setSessionId],
    );

    const handleEnterAsProvider = async (
        e: React.FormEvent<HTMLFormElement>,
    ) => {
        e.preventDefault();
        if (enteringAsProvider) return;
        setEnteringAsProvider(true);

        const { data, errors: videoCallErrors } =
            await apolloClient.mutate<EnterVideoCallResult>({
                mutation: enterVideoCallMutation,
                variables: {
                    sessionId,
                },
                context: {
                    clientName: GraphQLClientNames.telehealth,
                },
            });

        if (videoCallErrors || !data) {
            throw new Error('Error entering the call as provider');
        }

        setSessionIds({
            clientId: '',
            zoomAuthToken: data.enterVideoCall,
            sessionId,
            authToken: 'Not necessery here until now',
        });

        setStep(Step.call, 'null');
    };

    return (
        <form onSubmit={handleEnterAsProvider}>
            <div>
                Enter a cvc to skip the input-form and proceed to call as a
                normal client.
            </div>
            <div>
                Alternatively, enter another clients sessionId to join the room
                as fake-provider.
            </div>
            <StripeInput
                id="cvc"
                label="debug_cvc-ref"
                ref={cardCvcRef}
                classes={{ root: styles['half-width'] }}
            />
            <TextField
                label="Another clients sessionId"
                id="sessionId"
                name="sessionId"
                classes={{ root: styles.control }}
                onChange={handleChangeSessionId}
                value={sessionId}
            />
            <NextButton
                label="Enter another clients call"
                type="submit"
                disabled={
                    creditCardProcessing ||
                    vetspireIdLoading ||
                    enteringAsProvider ||
                    !sessionId
                }
                loading={
                    creditCardProcessing ||
                    vetspireIdLoading ||
                    enteringAsProvider
                }
                variant="grid"
            />
        </form>
    );
}

export default DebugSkipForm;
