import * as React from 'react';
import { SessionStatus } from '@bondvet/types/telehealth';
import VideoStreams from './VideoStreams';
import styles from './video.module.scss';
import VideoUI from './VideoUI';

type VideoProps = {
    zoomAuthToken: string;
    sessionName: string;
    sessionPassword: string;
    sessionStatus: SessionStatus;
    hideChatIcon?: boolean;
    chatOpen?: boolean;
    mirrorVideoOpen?: boolean;
    onEndCall?: () => void;
    setChatOpen?: (chatOpen: boolean) => void;
    onDisconnected?: () => void;
    largeMirrorVideo?: boolean;
};

export default function Video({
    zoomAuthToken,
    sessionName,
    sessionPassword,
    hideChatIcon = true,
    chatOpen = false,
    onEndCall,
    setChatOpen = () => {},
    mirrorVideoOpen,
    onDisconnected,
    largeMirrorVideo,
    sessionStatus,
}: VideoProps) {
    return (
        <div className={styles.root}>
            <VideoUI
                hideChatIcon={hideChatIcon}
                chatOpen={chatOpen}
                setChatOpen={setChatOpen}
                onEndCall={onEndCall}
            />
            {zoomAuthToken && sessionPassword && (
                <VideoStreams
                    zoomAuthToken={zoomAuthToken}
                    sessionName={sessionName}
                    sessionPassword={sessionPassword}
                    sessionStatus={sessionStatus}
                    mirrorVideoOpen={mirrorVideoOpen}
                    chatOpen={chatOpen}
                    onDisconnected={onDisconnected}
                    largeMirrorVideo={largeMirrorVideo}
                />
            )}
        </div>
    );
}
