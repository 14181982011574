import { SessionStatus } from '@bondvet/types/telehealth';
import classNames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useVideoContext from '../../../hooks/video/useVideoContext';
import VideoParticipant from './VideoParticipant';
import styles from './VideoStreams.module.scss';
import useIsMobile from '../../../hooks/useIsMobile';

type VideoStreamProps = {
    zoomAuthToken: string;
    sessionName: string;
    sessionPassword: string;
    sessionStatus: SessionStatus;
    fixedPosition?: boolean;
    isClient?: boolean;
    mirrorVideoOpen?: boolean;
    chatOpen?: boolean;
    onDisconnected?: () => void;
    largeMirrorVideo?: boolean;
};

function VideoStreams({
    sessionStatus,
    zoomAuthToken,
    sessionName,
    sessionPassword,
    fixedPosition = true,
    mirrorVideoOpen = true,
    chatOpen = false,
    isClient = true,
    onDisconnected,
    largeMirrorVideo = false,
}: VideoStreamProps) {
    const {
        otherParticipantJoined,
        localVideoTrack,
        joinSession,
        hasRemoteVideo,
        cameraOn,
        localVideoRef,
    } = useVideoContext();
    const intl = useIntl();

    const inCall =
        sessionStatus === SessionStatus.nurseCall ||
        sessionStatus === SessionStatus.veterinarianCall;

    const isMobile = useIsMobile();

    // create room and load remote-participants
    React.useEffect(() => {
        joinSession(
            zoomAuthToken,
            sessionName,
            sessionPassword,
            onDisconnected,
        );
    }, [
        joinSession,
        sessionName,
        sessionPassword,
        zoomAuthToken,
        onDisconnected,
    ]);

    const otherParticipantAway = inCall && !otherParticipantJoined;
    const noRemoteVideo = inCall && !hasRemoteVideo;

    return (
        <div
            className={classNames(styles.root, {
                [styles.fixed]: fixedPosition,
                [styles.foreground]: inCall,
            })}
        >
            {inCall && React.createElement('video-player-container')}
            {mirrorVideoOpen && (
                <VideoParticipant
                    key="client"
                    classes={{
                        root: inCall
                            ? classNames(styles['in-call-client'], {
                                  [styles['in-call-client-default']]:
                                      isMobile || !chatOpen,
                                  [styles['in-call-client-chat-open']]:
                                      !isMobile && chatOpen,
                                  [styles['in-call-client-large']]:
                                      largeMirrorVideo,
                              })
                            : classNames(
                                  styles['full-screen'],
                                  styles['client-waiting'],
                              ),
                        video: !cameraOn ? styles.hidden : '',
                    }}
                    videoTrack={cameraOn ? localVideoTrack : null}
                    draggable={inCall}
                    videoRef={localVideoRef}
                />
            )}
            {otherParticipantAway && (
                <p className={styles['alone-message']}>
                    {isClient
                        ? intl.formatMessage({
                              id: 'callPage.video.clientAlone',
                          })
                        : intl.formatMessage({
                              id: 'callPage.video.providerAlone',
                          })}
                </p>
            )}
            {noRemoteVideo && (
                <div className={styles['video-placeholder']}>
                    <img
                        src="/user.svg"
                        alt={intl.formatMessage({
                            id: 'callPage.video.videoPlaceholder',
                        })}
                    />
                </div>
            )}
        </div>
    );
}

export default VideoStreams;
