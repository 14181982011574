import { gql, useApolloClient } from '@apollo/client';
import classnames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { TelehealthAppType } from '@bondvet/types/telehealth';
import { OperationResult } from '@bondvet/types';
import { FormattedChatMessage, GraphQLClientNames } from 'lib/types';
import { useChatContext } from 'core/hooks/useChatContext';
import { REGEX_LEADING_AND_TRAILING_WHITESPACES } from '../../constants';
import { formatChatMessages } from '../../lib/chatMessages';
import PreviewList from '../Files/preview/PreviewList';
import ResizableTextArea from '../ResizableTextArea';
import styles from './Chat.module.scss';
import FileInputIcon from './FileUploadIcon';
import Message from './Message';

const SendMessageMutation = gql`
    mutation SendMessage($message: MessageInput!, $sessionId: String) {
        sendMessage(message: $message, sessionId: $sessionId) {
            success
            error
        }
    }
`;

export interface ChatProps {
    sessionId: string;
    appUser: TelehealthAppType;
    fullClientName: string;
    clientImage?: string | null;
    fullProviderName: string;
    providerImage?: string | null;
}

export default function Chat({
    appUser,
    sessionId,
    fullClientName,
    clientImage = null,
    fullProviderName,
    providerImage = null,
}: ChatProps): React.ReactElement {
    const intl = useIntl();
    const apolloClient = useApolloClient();

    const [textfieldRows, setTextfieldRows] = React.useState<number>(1);
    const [chatInput, setChatInput] = React.useState<string>('');
    const [chatMessages, setChatMessages] = React.useState<
        FormattedChatMessage[]
    >([]);
    const chatDivRef = React.useRef<HTMLDivElement>(null);

    const [attachments, setAttachments] = React.useState<ReadonlyArray<File>>(
        [],
    );
    const addAttachments = React.useCallback((files: ReadonlyArray<File>) => {
        setAttachments((prev) => [...prev, ...files]);
    }, []);
    const removeAttachment = React.useCallback((file: File) => {
        setAttachments((prev) => [
            ...prev.filter((attachment) => attachment !== file),
        ]);
    }, []);
    const resetAttachments = React.useCallback(() => {
        setAttachments([]);
    }, []);
    const { messages, setChatIsOpen } = useChatContext();

    React.useEffect(() => {
        setChatIsOpen(true);
        return () => {
            setChatIsOpen(false);
        };
    }, [setChatIsOpen]);

    React.useEffect(() => {
        const formattedChatMessages = formatChatMessages(
            messages,
            fullClientName,
            clientImage,
            fullProviderName,
            providerImage,
        );

        setChatMessages(formattedChatMessages);
    }, [
        sessionId,
        setChatMessages,
        fullClientName,
        clientImage,
        fullProviderName,
        providerImage,
        messages,
    ]);

    React.useEffect(() => {
        if (chatDivRef.current) {
            chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        }
    }, [chatMessages, attachments]);

    const sendChatMessage = React.useCallback(
        async (message: string, attachmentsToSend: ReadonlyArray<File>) => {
            const { data: chatMessageData, errors: chatMessageErrors } =
                await apolloClient.mutate<OperationResult>({
                    mutation: SendMessageMutation,
                    variables: {
                        message: {
                            message,
                            attachments: attachmentsToSend,
                        },
                        sessionId,
                    },
                    context: {
                        clientName: GraphQLClientNames.telehealth,
                    },
                });
            if (!chatMessageData?.success || chatMessageErrors) {
                // TODO handle failed message-sending
            }
        },
        [apolloClient, sessionId],
    );

    const resetInputs = React.useCallback(() => {
        setChatInput('');
        setTextfieldRows(1);
        resetAttachments();
    }, [resetAttachments]);

    const onSendMessage = React.useCallback(() => {
        if (chatInput === '' && attachments.length <= 0) {
            return;
        }

        const cleanChatMessage = chatInput.replace(
            REGEX_LEADING_AND_TRAILING_WHITESPACES,
            '',
        );

        sendChatMessage(cleanChatMessage, attachments).then();
        resetInputs();
    }, [attachments, chatInput, resetInputs, sendChatMessage]);

    const hasSameAuthorAsPreviousMessage = (
        messageArray: FormattedChatMessage[],
        index: number,
    ) => {
        if (index <= 0 || !messageArray || messageArray.length <= 1)
            return false;
        return messageArray[index]?.sender === messageArray[index - 1]?.sender;
    };

    return (
        <>
            <div
                className={classnames(styles['chat-content'], {
                    [styles['chat-content-client']]:
                        appUser === TelehealthAppType.client,
                })}
                ref={chatDivRef}
            >
                <div className={styles['chat-messages-container']}>
                    {chatMessages.map((msg, index) => (
                        <Message
                            {...msg}
                            key={msg.timestamp}
                            userIsSender={msg.sender === appUser}
                            hideSender={hasSameAuthorAsPreviousMessage(
                                chatMessages,
                                index,
                            )}
                        />
                    ))}
                </div>
                <div className={styles['file-upload-preview']}>
                    <PreviewList
                        files={attachments}
                        onDelete={removeAttachment}
                        wrapperClasses={{
                            root: classnames(styles['preview-wrapper']),
                        }}
                    />
                </div>
            </div>
            <div className={styles['input-bar']}>
                <div className={styles['chat-input']}>
                    <ResizableTextArea
                        minRows={1}
                        maxRows={5}
                        value={chatInput}
                        setValue={setChatInput}
                        onSubmit={onSendMessage}
                        rows={textfieldRows}
                        setRows={setTextfieldRows}
                    />
                    <div className={styles.upload}>
                        <FileInputIcon addAttachments={addAttachments} />
                    </div>
                </div>
                <button
                    type="submit"
                    data-cy="submitChat"
                    className={styles.send}
                    onClick={onSendMessage}
                >
                    <span>
                        {intl.formatMessage({
                            id: 'callPage.chat.send',
                        })}
                    </span>
                </button>
            </div>
        </>
    );
}
